import React, { useEffect, useState } from "react";
import Container from "./styled";
import Sound from "react-sound";
import Animacion from "./animacion";
import Timbre from "./timbre.mp3";
import { formatValue } from "react-currency-input-field";
import axios from "axios";
import url from "../../../utils/url";
import { useLocation } from "react-router-dom";
import Pregunta from "../../home/faqs/pregunta";
import { Fade } from "react-awesome-reveal";

export default function Contrataste() {
  const [isActiveSound, setIsActiveSound] = useState(Sound.status.PLAYING);
  // Estado que determina si esta abierta o no la question
  const [isActiveUno, setIsActiveUno] = useState(false);
  const [isActiveDos, setIsActiveDos] = useState(false);
  const [isActiveTres, setIsActiveTres] = useState(false);
  const [isActiveCuatro, setIsActiveCuatro] = useState(false);
  const [isActiveCinco, setIsActiveCinco] = useState(false);
  const [isActiveSeis, setIsActiveSeis] = useState(false);

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de las questions
  const handleToggleUno = () => {
    setIsActiveUno(!isActiveUno);
    setIsActiveDos(false);
    setIsActiveTres(false);
    setIsActiveCuatro(false);
    setIsActiveCinco(false);
    setIsActiveSeis(false);
    setIsActiveSound(Sound.status.STTOPED);
  };

  const handleToggleDos = () => {
    setIsActiveUno(false);
    setIsActiveDos(!isActiveDos);
    setIsActiveTres(false);
    setIsActiveCuatro(false);
    setIsActiveCinco(false);
    setIsActiveSeis(false);
    setIsActiveSound(Sound.status.STTOPED);
  };

  const handleToggleTres = () => {
    setIsActiveUno(false);
    setIsActiveDos(false);
    setIsActiveTres(!isActiveTres);
    setIsActiveCuatro(false);
    setIsActiveCinco(false);
    setIsActiveSeis(false);
    setIsActiveSound(Sound.status.STTOPED);
  };

  const handleToggleCuatro = () => {
    setIsActiveUno(false);
    setIsActiveDos(false);
    setIsActiveTres(false);
    setIsActiveCuatro(!isActiveCuatro);
    setIsActiveCinco(false);
    setIsActiveSeis(false);
    setIsActiveSound(Sound.status.STTOPED);
  };

  const handleToggleCinco = () => {
    setIsActiveUno(false);
    setIsActiveDos(false);
    setIsActiveTres(false);
    setIsActiveCuatro(false);
    setIsActiveCinco(!isActiveCinco);
    setIsActiveSeis(false);
    setIsActiveSound(Sound.status.STTOPED);
  };

  const handleToggleSeis = () => {
    setIsActiveUno(false);
    setIsActiveDos(false);
    setIsActiveTres(false);
    setIsActiveCuatro(false);
    setIsActiveCinco(false);
    setIsActiveSeis(!isActiveSeis);
    setIsActiveSound(Sound.status.STTOPED);
  };

  // Data para enviar con todos los datos que completo el usuario mas el seguro
  const [data, setData] = useState({
    nombre:
      localStorage.getItem("segurocomercio-nombre").charAt(0).toUpperCase() +
      localStorage.getItem("segurocomercio-nombre").slice(1),
    apellido:
      localStorage.getItem("segurocomercio-apellido").charAt(0).toUpperCase() +
      localStorage.getItem("segurocomercio-apellido").slice(1),
    email: localStorage.getItem("segurocomercio-email"),
    provincia: localStorage.getItem("segurocomercio-provincia"),
    actividad:
      localStorage.getItem("segurocomercio-actividad").charAt(0).toUpperCase() +
      localStorage.getItem("segurocomercio-actividad").slice(1),
    importe: formatValue({
      value: localStorage.getItem("segurocomercio-importe"),
      groupSeparator: ".",
      decimalSeparator: ",",
      prefix: "$",
      suffix: " ARS",
    }),
    metros: localStorage.getItem("segurocomercio-metros"),
    telefono: localStorage.getItem("segurocomercio-telefono"),
    codigoArea: localStorage.getItem("segurocomercio-codigoArea"),
  });

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let id_operacion = query.get("op");

  useEffect(() => {
    axios.post(`${url}/api/sendEmails/contratacion/${id_operacion}`, {
      compania: "Federación Patronal",
      plan: "Plan Comercial",
    });
  }, []);

  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      <Sound
        url={Timbre}
        autoLoad={true}
        playStatus={isActiveSound}
        volume={80}
      />
      {/* Contrataste Container */}
      <div className="contrataste-container opacidad04">
        {/* Animación Container */}
        <div id="animacion-container">
          <span></span>
          <Animacion></Animacion>
        </div>
        <h3>¡{data.nombre}, cotizaste tu seguro!</h3>
        {/* Texto Agradecimiento */}
        <p id="texto-contacto">
          Un ejecutivo se contactará con vos en las próximas horas para
          finalizar la contratación.
          <br />
          ¡Gracias por cotizar con SeguroComercio y Federación Patronal!
        </p>
        {/* Max Width Coberturas Container */}
        <div id="max-width-coberturas-container">
          {/* Coberturas Container */}
          <div className="coberturas-container opacidad02">
            {/* Logo y Plan Container */}
            <div className="logo-plan-container">
              <img
                alt="Logo Federeción Patronal"
                src="/imagenes/logos/federacionpatronal2.webp"
              ></img>
              <p>Plan Comercial</p>
            </div>
            {/* Checks Container */}
            <div className="checks-container">
              {/* Incendio Edificio */}
              <span>
                <p>Icen. Edificio</p>
                <span class="material-symbols-outlined">done</span>
              </span>
              {/* Incendio Contenido */}
              <span>
                <p>Icen. Contenido</p>
                <span class="material-symbols-outlined">done</span>
              </span>
              {/* Responsabilidad Civil */}
              <span>
                <p>Resp. Civil</p>
                <span class="material-symbols-outlined">done</span>
              </span>
              {/* Electrónicos */}
              <span>
                <p>Electrónicos</p>
                <span class="material-symbols-outlined">done</span>
              </span>
              {/* Robo Contenido */}
              <span>
                <p>Robo Contenido</p>
                <span class="material-symbols-outlined">done</span>
              </span>
              {/* Responsabilidad Civil */}
              <span>
                <p>Resp. Civil Lin.</p>
                <span class="material-symbols-outlined">done</span>
              </span>
              {/* Dinero en caja */}
              <span>
                <p>Dinero Caja</p>
                <span class="material-symbols-outlined">done</span>
              </span>
              {/* Urgencias */}
              <span>
                <p>Urgencias</p>
                <span class="material-symbols-outlined">done</span>
              </span>
            </div>
            {/* Precios Container */}
            <div className="precio-container"></div>
            {/* Datos Comercio */}
            <div className="datos-comercio-mobile">
              <img
                alt="Ícono de comercio"
                src="/imagenes/pasos/comercio.svg"
              ></img>
              <p>Comercio</p>
            </div>
          </div>
          {/* Datos Container */}
          <div id="datos-container">
            {/* Datos Comercio */}
            <div className="datos-comercio">
              <img
                alt="Ícono de comercio"
                src="/imagenes/pasos/comercio.svg"
              ></img>
              <p>Comercio</p>
            </div>
            {/* Datos Cotizacion */}
            <div id="datos-cotizacion">
              {/* Provincia */}
              <div className="dato">
                <p>Pronvicia</p>
                <p>{data.provincia}</p>
              </div>
              {/* Actividad */}
              <div className="dato">
                <p>Actividad</p>
                <p>{data.actividad}</p>
              </div>
              {/* Importe */}
              <div className="dato">
                <p>Importe de mercadería</p>
                <p>{data.importe}</p>
              </div>
              {/* Plan Seleccionado */}
              <div className="dato">
                <p>m² cubiertos</p>
                <p>{data.metros} m²</p>
              </div>
            </div>
          </div>
        </div>
        {/* Datos Preparados */}
        <div className="datos-preparados">
          <h4>
            Para agilizar el proceso,
            <span>tené listos los siguientes datos</span>:
          </h4>
          <div>
            <p>Foto del exterior del comercio.</p>
          </div>
          <div>
            <p>Foto del interior del comercio.</p>
          </div>
          <div>
            <p>Foto de cerraduras de entrada al comercio.</p>
          </div>
        </div>
      </div>
      {/* Datos Plan Federación */}
      <div className="datos-plan-federacion">
        <h3 className="opacidad05">
          Conocé todos los
          <span> datalles de tu plan</span>:
        </h3>
        {/* Preguntas Container */}
        <div id="preguntas-container">
          {/* Pregunta 1 */}
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className={
              isActiveUno == true
                ? "pregunta-container-abierta"
                : "pregunta-container"
            }
          >
            <Pregunta
              title="Incendio de edificio y contenido general"
              clickFunction={handleToggleUno}
              isActive={isActiveUno}
            >
              Edificio: Se entiende por edificios o construcciones los adheridos al suelo en
              forma permanente, sin exclusión de parte alguna. Las instalaciones
              unidas a ellos con carácter permanente se considerarán “edificios
              o construcciones” en la medida que resulten un complemento de los
              mismos y sean de propiedad del dueño del edificio o construcción.
              <br></br>Contenido general: Se entiende al conjunto de cosas
              muebles y mercaderías en el comercio.<br></br>Riesgo Cubierto
              Incendio:<br></br>• Acción directa o indirecta del fuego, rayo y/o
              explosión.<br></br>• Huelga y/o lockout y tumulto popular,
              vandalismo o malevolencia.
              <br></br>• Daños por humo.<br></br>• Impacto de aeronaves y
              vehículos terrestres y/o su carga transportada.<br></br>•
              Demolición como consecuencia de un hecho cubierto, hasta el valor
              de reposición y/o reconstrucción y/o reparación.
            </Pregunta>
          </Fade>
          {/* Pregunta 2 */}
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className={
              isActiveDos == true
                ? "pregunta-container-abierta"
                : "pregunta-container"
            }
          >
            <Pregunta
              title="Robo de mobiliario"
              clickFunction={handleToggleDos}
              isActive={isActiveDos}
            >
              Riesgo cubierto:<br></br>Robo y/o hurto del contenido que se halle
              en el comercio, que pertenezca al ssegurado. En caso de robo de
              mercaderias el asegurado deberá contar con las facturas de compra
              de tales mercaderias.
            </Pregunta>
          </Fade>
          {/* Pregunta 3 */}
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className={
              isActiveTres == true
                ? "pregunta-container-abierta"
                : "pregunta-container"
            }
          >
            <Pregunta
              title="Electrónicos"
              clickFunction={handleToggleTres}
              isActive={isActiveTres}
            >
              Riesgo cubierto:<br></br>Robo el asegurador indemnizará el daño o
              pérdida que afectase a los aparatos o equipos electronicos por la
              acción directa o indirecta de incendio, rayo, explosión, robo,
              hurto o accidente mientras se encuentre dentro del domicilio del
              riesgo asegurado. La presente cobertura se extiende a los daños
              causados por fallas en el aprovisionamiento de la corriente
              eléctrica de la red
            </Pregunta>
          </Fade>
          {/* Pregunta 4 */}
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className={
              isActiveCuatro == true
                ? "pregunta-container-abierta"
                : "pregunta-container"
            }
          >
            <Pregunta
              title="Cristales"
              clickFunction={handleToggleCuatro}
              isActive={isActiveCuatro}
            >
              Riesgo cubierto:<br></br>Los daños sufridos por los cristales y
              demás piezas vítreas colocadas en forma vertical.
            </Pregunta>
          </Fade>
          {/* Pregunta 5 */}
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className={
              isActiveCinco == true
                ? "pregunta-container-abierta"
                : "pregunta-container"
            }
          >
            <Pregunta
              title="Responsabilidad civil comprensiva"
              clickFunction={handleToggleCinco}
              isActive={isActiveCinco}
            >
              Riesgo cubierto:<br></br>Se cubre la Responsabilidad Civil
              emergente de hechos que se vinculan con actividad profesional,
              imputables al asegurado
            </Pregunta>
          </Fade>
          {/* Pregunta 6 */}
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className={
              isActiveSeis == true
                ? "pregunta-container-abierta"
                : "pregunta-container"
            }
          >
            <Pregunta
              title="Responsabilidad civil linderos"
              clickFunction={handleToggleSeis}
              isActive={isActiveSeis}
            >
              Riesgo cubierto:<br></br>Daños a cosas materiales de terceras
              personas en las inmediaciones a causa de Incendio, rayo,
              explosión, descargas eléctricas y escapes de gas. Se exceptúa la
              indemnización de daños que ocasionen muerte o lesiones.
            </Pregunta>
          </Fade>
        </div>
      </div>
    </Container>
  );
}
