import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Container
import Container from "./styled";
// Iconos
import { BiError } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { FaBuilding } from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
// Librerias
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import CurrencyInput from "react-currency-input-field";
// Animaciones
import Animacion from "./ilustracion-animada";
import AnimacionLoading from "./animacion-loading";
// Otros
import axios from "axios";
import url from "../../utils/url";
// Array de actividades
import actividades from "./actividades";

export default function Cotizador({ subtitle, isReferidosHeaderOpen }) {
  // Estado para el loading
  const [loading, setLoading] = useState(false);
  // Estado para el input de actividades
  const [actividadOpen, setActividadOpen] = useState(false);
  // Valores de los inputs
  const [inputValues, setInputValues] = useState({
    nombre:
      localStorage.getItem("segurocomercio-nombre") !== null
        ? localStorage.getItem("segurocomercio-nombre")
        : "",
    apellido:
      localStorage.getItem("segurocomercio-apellido") !== null
        ? localStorage.getItem("segurocomercio-apellido")
        : "",
    email:
      localStorage.getItem("segurocomercio-email") !== null
        ? localStorage.getItem("segurocomercio-email")
        : "",
    provincia:
      localStorage.getItem("segurocomercio-provincia") !== null
        ? localStorage.getItem("segurocomercio-provincia")
        : "",
    codigoPostal:
    localStorage.getItem("segurocomercio-codigoPostal") !== null
      ? localStorage.getItem("segurocomercio-codigoPostal")
      : "",
    importe: "",
    actividad: "",
    telefono:
      localStorage.getItem("segurocomercio-telefono") !== null
        ? localStorage.getItem("segurocomercio-telefono")
        : "",
    codigoArea:
      localStorage.getItem("segurocomercio-codigoArea") !== null
        ? localStorage.getItem("segurocomercio-codigoArea")
        : "",
    metros: "",
  });
  // Errores del formulario
  const [formErrors, setFormErrors] = useState({});
  // Pop-up Errores
  const [popupErrores, setPopupErrores] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // Params provenientes de la URL
  const [searchParams, setSearchParams] = useSearchParams();
  const [ip, setIp] = useState("");
  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_campaign = searchParams.get("utm_campaign");
  const fb_id = searchParams.get("fbclid");
  const influencer = searchParams.get("influencer")
    ? searchParams.get("influencer")
    : 0;
  const [canal_url, setCanal_url] = useState(null);
  // Network Params
  const network = searchParams.get("network");
  const networkCampaign = searchParams.get("campaign");
  const networkGroup = searchParams.get("group");
  const networkCreative = searchParams.get("creative");
  const networkKeyword = searchParams.get("keyword");
  const networkDevice = searchParams.get("device");
  const networkMatchtype = searchParams.get("matchtype");
  const networkClid = searchParams.get("gclid");

  // Toggle Pop-up Errores
  const togglePopupErrores = () => {
    setPopupErrores(!popupErrores);
  };

  // Obtiene la IP del usuario
  useEffect(() => {
    const data = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIp(res.data.IPv4);
    };
    data();

    // Obtiene la ruta desde la que se ingresá (canales)
    const url = window.location.toString().split("/");
    const sufijo = url[url.length - 1];
    let canal;
    switch (sufijo) {
      case "gs":
        canal = 1;
        break;
      case "gd":
        canal = 2;
        break;
      case "in":
        canal = 38;
        break;
      case "fb":
        canal = 7;
        break;
      case "tiktok":
        canal = 135;
        break;
      case "twitch":
        canal = 141;
        break;
      default:
        canal = null;
        break;
    }
    setCanal_url(canal);
  }, []);

  // Obtener prefijos de la base para validar en conjunto con el ingresado por el usuario.
  useEffect(() => {
    const getPrefixes = async () => {
      try {
      } catch (error) {
        console.log("Hubo un error", error);
      }
    };
    getPrefixes();
  }, []);

  // Setea el valor de los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    // Guarda el valor de cada input en el localStorage con su nombre para autocompletar el paso 3
    localStorage.setItem("segurocomercio-" + [name], value);
  };

  // Setea el valor del input de actividad
  const handleChangeActividades = (valor) => {
    setInputValues((prev) => ({ ...prev, actividad: valor }));
    localStorage.setItem("segurocomercio-actividad", valor);
  };

  // Setea el valor del los inputs currency
  const handleChangeCurrency = (valor, nombre) => {
    setInputValues((prev) => ({ ...prev, [nombre]: valor }));
    localStorage.setItem("segurocomercio-" + [nombre], valor);
  };

  // Validar Formulario
  const validateForm = (e) => {
    e.preventDefault();
    let errors = {};

    // Validar nombre
    if (!inputValues.nombre) {
      errors.nombre = "El nombre es requerido";
    } else if (inputValues.nombre.length < 2) {
      errors.nombre = "El nombre debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.nombre
      )
    ) {
      errors.nombre = "El nombre ingresado no es válido";
    }

    // Validar apellido
    if (!inputValues.apellido) {
      errors.apellido = "El apellido es requerido";
    } else if (inputValues.apellido.length < 2) {
      errors.apellido = "El apellido debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.apellido
      )
    ) {
      errors.apellido = "El apellido ingresado no es válido";
    }

    // Validar correo electrónico
    if (!inputValues.email) {
      errors.email = "El correo electrónico es requerido";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.email)) {
      errors.email = "El correo electrónico ingresado no es valido";
    }

    // Validar provincia
    if (!inputValues.provincia) {
      errors.provincia = "La provincia es requerida.";
    }

    // Validar código postal
    if (!inputValues.codigoPostal) {
      errors.codigoPostal = "El código postal es requerido";
    } else if (
      inputValues.codigoPostal.length <= 3
    ) {
      errors.codigoPostal =
        "El código postala debe tener al menos 4 caracteres";
    }

    // Validar actividad
    if (!inputValues.actividad) {
      errors.actividad = "La actividad es requerida.";
    }

    // Validar importe
    if (!inputValues.importe) {
      errors.importe = "El importe es requerido.";
    }

    // Validar teléfono
    if (!inputValues.telefono) {
      errors.telefono = "El teléfono es requerido";
    } else if (inputValues.telefono.length < 8) {
      errors.telefono = "El teléfono es menor a 8 digitos";
    }

    // Validar metros cubiertos
    if (!inputValues.metros) {
      errors.metros = "Los metros cuadrados cubiertos son requeridos";
    }

    // Validar código de área
    if (!inputValues.codigoArea) {
      errors.codigoArea = "El código de área es requerido";
    } else if (
      inputValues.codigoArea.length < 2 ||
      inputValues.codigoArea.length > 5
    ) {
      errors.codigoArea =
        "El código de área debe tener más de 2 digitos y menos de 5";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      sendData();
    } else if (popupErrores != true) {
      togglePopupErrores();
    }
  };

  const sendData = async () => {
    const origen = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    )
      ? "mobile"
      : "web";

    const data = {
      nombre: inputValues.nombre + " " + inputValues.apellido,
      email: inputValues.email,
      telefono: `(${inputValues.codigoArea}) ${inputValues.telefono}`,
      ip_address: ip,
      tipo: inputValues.producto,
      provincia: inputValues.provincia,
      codigo_postal: inputValues.codigoPostal,
      actividad: inputValues.actividad,
      importe_mercaderia: inputValues.importe,
      metros_cubiertos: inputValues.metros,
      origen: origen,
      utm_source: utm_source ? utm_source : "",
      utm_medium: utm_medium ? utm_medium : "",
      utm_campaign: utm_campaign ? utm_campaign : "",
      influencer: influencer,
      canal_url: canal_url,
      fb_id: fb_id,
      network: network,
      networkCampaign: networkCampaign,
      networkGroup: networkGroup,
      networkCreative: networkCreative,
      networkKeyword: networkKeyword,
      networkDevice: networkDevice,
      networkMatchtype: networkMatchtype,
      networkClid: networkClid,
    };

    try {
      const post = await axios.post(`${url}/api/cotizaciones/`, data);

      if (post.status === 200) {
        navigate(`/pasos/?op=${post.data.numero}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Toogle Actividad
  const toggleActividad = () => {
    setActividadOpen(true);
  };

  // Hace que si se hace click afuera del input de actividades se desactiva
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActividadOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const referencia = useRef(null);
  const inputActividad = useRef(null);
  useOutsideAlerter(referencia);

  return (
    <Container
      isReferidosHeaderOpen={isReferidosHeaderOpen}
      name="cotizador"
      actividadOpen={actividadOpen}
    >
      {/* Pop up Errores */}
      {popupErrores == true && (
        <div id="popup-error">
          {formErrors.nombre != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.nombre}
            </p>
          )}
          {formErrors.apellido != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.apellido}
            </p>
          )}
          {formErrors.email != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.email}
            </p>
          )}
          {formErrors.codigoArea != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.codigoArea}
            </p>
          )}
          {formErrors.telefono != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.telefono}
            </p>
          )}
          {formErrors.actividad != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.actividad}
            </p>
          )}
          {formErrors.importe != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.importe}
            </p>
          )}
          {formErrors.metros != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.metros}
            </p>
          )}
          {formErrors.codigoPostal != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.codigoPostal}
            </p>
          )}
        </div>
      )}
      {/* Formulario Container */}
      <div id="form-titulo-container" className="opacidad05">
        {/* Titulos */}
        <div id="titulos-container">
          <h1 className="opacidad05">¡Cotizá tu seguro!</h1>
          <h3 className="opacidad05">{subtitle}</h3>
        </div>
        {/* Formulario Container */}
        <div id="form-container" className="opacidad05">
          {/* Formulario */}
          <form autoComplete="off">
            {/* Inputs Container */}
            <div id="inputs-container">
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Nombre */}
              <div className="input-container" id="input-apellido-container">
                <input
                  type="text"
                  id="input-apellido"
                  name="apellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.apellido}
                  className={formErrors.apellido == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.apellido == null || inputValues.apellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-apellido"
                >
                  Apellido
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input Codigo de Area */}
              <div className="input-container" id="input-codigo-area-container">
                <input
                  type="number"
                  name="codigoArea"
                  id="input-codigo-area"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.codigoArea}
                  className={formErrors.codigoArea == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.codigoArea == null ||
                    inputValues.codigoArea == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-codigo-area"
                >
                  Cod. Área
                </label>
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="number"
                  id="input-telefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                  className={formErrors.telefono == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-telefono"
                >
                  Teléfono
                </label>
              </div>
              {/* Provincia */}
              <div className="select-container" id="select-provincia-container">
                <select
                  id="select-provincia"
                  name="provincia"
                  value={inputValues.provincia}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={formErrors.provincia == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  <option value="Buenos Aires">Buenos Aires</option>
                  <option value="Ciudad de Buenos Aires">
                    Ciudad de Buenos Aires
                  </option>
                  <option value="Catamarca">Catamarca</option>
                  <option value="Chaco">Chaco</option>
                  <option value="Chubut">Chubut</option>
                  <option value="Cordoba">Córdoba</option>
                  <option value="Corrientes">Corrientes</option>
                  <option value="Entre Rios">Entre Rios</option>
                  <option value="Formosa">Formosa</option>
                  <option value="Jujuy">Jujuy</option>
                  <option value="La Pampa">La Pampa</option>
                  <option value="La Rioja">La Rioja</option>
                  <option value="Mendoza">Mendoza</option>
                  <option value="Misiones">Misiones</option>
                  <option value="Neuquen">Neuquén</option>
                  <option value="Rio Negro">Río Negro</option>
                  <option value="Salta">Salta</option>
                  <option value="San Juan">San Juan</option>
                  <option value="San Luis">San Luis</option>
                  <option value="Santa Cruz">Santa Cruz</option>
                  <option value="Santa Fe">Santa Fé</option>
                  <option value="Santiago del Estero">
                    Santiago del Estero
                  </option>
                  <option value="Tierra del Fuego">Tierra del Fuego</option>
                  <option value="Tucuman">Tucumán</option>
                </select>
                <label
                  className={
                    inputValues.provincia == null || inputValues.provincia == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-provincia"
                >
                  Provincia
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>              
              {/* Input Codigo Postal */}
              <div className="input-container" id="input-codigo-postal-container">
                <input
                  type="text"
                  name="codigoPostal"
                  id="input-codigo-postal"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.codigoPostal}
                  className={formErrors.codigoPostal == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.codigoPostal == null ||
                    inputValues.codigoPostal == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-codigo-postal"
                >
                  Cod. Postal
                </label>
              </div>
              {/* Input Actividad */}
              <div
                className="input-container"
                id="input-actividad-container"
                ref={referencia}
              >
                <TextInput
                  trigger={[""]}
                  options={{
                    "": actividades,
                  }}
                  id="input-actividad"
                  onChange={(valor) => {
                    handleChangeActividades(valor);
                  }}
                  class={formErrors.actividad == null ? "" : "input-error"}
                  spacer=""
                  onClick={toggleActividad}
                  ref={inputActividad}
                  Component="input"
                  value={inputValues.actividad}
                  matchAny={true}
                />
                <label
                  className={
                    inputValues.actividad == null || inputValues.actividad == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-actividad"
                  id="label-actividad"
                >
                  Actividad
                </label>
              </div>
              {/* Input Importe */}
              <div className="input-container" id="input-importe-container">
                <CurrencyInput
                  id="input-importe"
                  name="importe"
                  prefix="$"
                  suffix=" ARS"
                  decimalsLimit={2}
                  decimalSeparator=","
                  groupSeparator="."
                  maxLength={13}
                  onValueChange={(value, name) =>
                    handleChangeCurrency(value, name)
                  }
                  className={formErrors.importe == null ? "" : "input-error"}
                />
                <label
                  className={
                    inputValues.importe == null ||
                    inputValues.importe == "" ||
                    inputValues.importe == "undefined"
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-importe"
                >
                  Importe de mercadería
                </label>
                <Tippy
                  content="Si tu comercio no cuenta con mercadería, deja este valor en $0 ARS"
                  animation="scale"
                >
                  <span>
                    <AiOutlineQuestionCircle />
                  </span>
                </Tippy>
              </div>
              {/* Input Metros Cubiertos */}
              <div className="input-container" id="input-metros-container">
                <input
                  type="number"
                  id="input-metros"
                  name="metros"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.metros}
                  className={formErrors.metros == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.metros == null ||
                    inputValues.metros == "" ||
                    inputValues.metros == "undefined"
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-metros"
                >
                  m² cubiertos
                </label>
              </div>
            </div>
            {/* Botones Container */}
            <div id="botones-container">
              {/* Whatsapp Button */}
              <Tippy content="Días hábiles de 9 a 18hs" animation="scale">
                <a
                  href="https://api.whatsapp.com/send?phone=5491162255399&text=%C2%A1Hola%2C%20necesito%20ayuda%20para%20cotizar%20una%20bicicleta!%20Ingresé%20desde%20la%20web"
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  Necesito ayuda
                </a>
              </Tippy>
              {/* Cotizar Button */}
              <button
                type="submit"
                onClick={validateForm}
                className="submit-button"
              >
                Cotizá tu seguro
                <span>
                  <FaBuilding></FaBuilding>
                </span>
              </button>
            </div>
          </form>
          {loading == true && (
            <div id="loading-container">
              <div id="animacion-loading">
                {/* Animacion */}
                <AnimacionLoading></AnimacionLoading>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Animacion Container */}
      <div id="animacion-container" className="opacidad08">
        {/* Animacion */}
        <Animacion></Animacion>
      </div>
    </Container>
  );
}
