import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import { BiError } from "react-icons/bi";
import axios from "axios";
import url from "../../../../utils/url";
import Container from "./styled";

export default function PasoUno({ pasoSiguiente }) {
  // Valores de los inputs
  const [inputValues, setInputValues] = useState({
    nombre:
      localStorage.getItem("segurocomercio-nombre") !== null
        ? localStorage.getItem("segurocomercio-nombre")
        : "",
    apellido:
      localStorage.getItem("segurocomercio-apellido") !== null
        ? localStorage.getItem("segurocomercio-apellido")
        : "",
    email:
      localStorage.getItem("segurocomercio-email") !== null
        ? localStorage.getItem("segurocomercio-email")
        : "",
    provincia:
      localStorage.getItem("segurocomercio-provincia") !== null
        ? localStorage.getItem("segurocomercio-provincia")
        : "",
    telefono:
      localStorage.getItem("segurocomercio-telefono") !== null
        ? localStorage.getItem("segurocomercio-telefono")
        : "",
    codigoArea:
      localStorage.getItem("segurocomercio-codigoArea") !== null
        ? localStorage.getItem("segurocomercio-codigoArea")
        : "",
  });

  // Errores del formulario
  const [formErrors, setFormErrors] = useState({});

  // Pop-up Errores
  const [popupErrores, setPopupErrores] = useState(false);

  // Toggle Pop-up Errores
  const togglePopupErrores = () => {
    setPopupErrores(!popupErrores);
  };

  // Setea el valor de los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    // Guarda el valor de cada input en el localStorage con su nombre para autocompletar el paso 3
    localStorage.setItem("segurocomercio-" + [name], value);
  };

  // Captcha
  function onChange(value) {
    setInputValues((prev) => ({ ...prev, ["captcha"]: value }));
  }

  // Validar Formulario
  const validateForm = (e) => {
    e.preventDefault();
    let errors = {};

    // Validar nombre
    if (!inputValues.nombre) {
      errors.nombre = "El nombre es requerido";
    } else if (inputValues.nombre.length < 2) {
      errors.nombre = "El nombre debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.nombre
      )
    ) {
      errors.nombre = "El nombre ingresado no es válido";
    }

    // Validar apellido
    if (!inputValues.apellido) {
      errors.apellido = "Este apellido es requerido";
    } else if (inputValues.apellido.length < 2) {
      errors.apellido = "El apellido debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.apellido
      )
    ) {
      errors.apellido = "El apellido ingresado no es válido";
    }

    // Validar correo electrónico
    if (!inputValues.email) {
      errors.email = "El correo electrónico es requerido";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.email)) {
      errors.email = "El correo electrónico ingresado no es valido";
    }

    // DNI
    if (!inputValues.dni) {
      errors.dni = "El DNI es requerido.";
    }

    // Validar teléfono
    if (!inputValues.telefono) {
      errors.telefono = "El teléfono es requerido";
    } else if (inputValues.telefono.length < 8) {
      errors.telefono = "El teléfono es menor a 8 digitos";
    }

    // Validar Mensaje
    if (!inputValues.mensaje) {
      errors.mensaje = "Tu comentario es requerido.";
    }

    // Validar captcha
    if (inputValues.captcha == undefined) {
      errors.captcha = "Confirme la verificación reCaptcha";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      sendMail();
      pasoSiguiente();
    } else if (popupErrores != true) {
      togglePopupErrores();
    }
  };

  // Enviar mail
  const sendMail = async () => {
    const mailContent = `
      <html><body>
        <h1>Baja de servicio SeguroComercio</h1>
        <h2>Importante: Por ley debe ser respondido dentro de las próximas 24hs hábiles</h2>
        <p><b>Nombre:</b> ${inputValues.nombre}</p>
        <p><b>Apellido:</b> ${inputValues.apellido}</p>
        <p><b>DNI:</b> ${inputValues.dni}</p>
        <p><b>Email:</b> ${inputValues.email}</p>
        <p><b>Teléfono:</b> ${inputValues.telefono}</p>
        <p><b>Motivo:</b> ${inputValues.mensaje}</p>
      </body></html>
      `;

    const mailData = {
      mail: ["emision@seguroweb.com.ar"],
      subject: `IMPORTANTE Baja de Servicio`,
      htmlContent: mailContent,
    };

    const sendMail = await axios.post(
      `${url}/api/sendEmails?from=interno`,
      mailData
    );
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      {/* Pop up Errores */}
      {popupErrores == true && (
        <div id="popup-error">
          {formErrors.nombre != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.nombre}
            </p>
          )}
          {formErrors.apellido != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.apellido}
            </p>
          )}
          {formErrors.email != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.email}
            </p>
          )}
          {formErrors.dni != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.dni}
            </p>
          )}
          {formErrors.telefono != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.telefono}
            </p>
          )}
          {formErrors.mensaje != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.mensaje}
            </p>
          )}
          {formErrors.captcha != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.captcha}
            </p>
          )}
        </div>
      )}
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container" className="opacidad05">
          <h3>¿Querés dar de baja tu póliza?</h3>
          <p id="completa">
            Completá el siguiente formulario para poder solicitarlo, dentro de
            las próximas 24hs hábiles nos contactaremos para otorgarte tu número
            de solicitud generado y avanzar con el proceso.
          </p>
          <form>
            <div id="datos-personales">
              <h4>Datos personales</h4>
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Apellido */}
              <div className="input-container" id="input-apellido-container">
                <input
                  type="text"
                  id="input-apellido"
                  name="apellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.apellido}
                  className={formErrors.apellido == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.apellido == null || inputValues.apellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-apellido"
                >
                  Apellido
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input DNI */}
              <div className="input-container" id="input-dni-container">
                <input
                  type="number"
                  id="input-dni"
                  name="dni"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.dni}
                  className={formErrors.dni == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.dni == null || inputValues.dni == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-dni"
                >
                  DNI
                </label>
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="number"
                  id="input-telefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                  className={formErrors.telefono == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-telefono"
                >
                  Teléfono
                </label>
              </div>
            </div>
            <div id="mensaje-motivo">
              <h4>Motivo de la baja</h4>
              {/* Textarea Mensaje */}
              <div className="input-container" id="textarea-mensaje-container">
                <textarea
                  type="text"
                  id="input-mensaje"
                  name="mensaje"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.mensaje ? inputValues.mensaje : ""}
                  className={formErrors.mensaje == null ? "" : "input-error"}
                ></textarea>
                <label
                  className={
                    inputValues.mensaje == null || inputValues.mensaje == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="input-mensaje"
                >
                  Ingresá tu comentario
                </label>
              </div>
            </div>
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={onChange}
              />
            </div>
            <div id="boton-container">
              <button type="submit" onClick={validateForm}>
                Enviar<span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" className="opacidad05" style={style}>
                <p>Más información</p>
                <a
                  target="_blank"
                  href="https://www.argentina.gob.ar/justicia/derechofacil/leysimple/boton-arrepentimiento#:~:text=%C2%BFQu%C3%A9%20es%20el%20bot%C3%B3n%20de,inicio%20del%20sitio%20de%20Internet."
                >
                  Argentina.gob.ar
                </a>
                <div>
                  <p>Ver más</p>
                  <Boton to="/arrepentimiento-de-compra" onClick={goToTop}>
                    Arrepentimiento de compra
                  </Boton>
                  <Boton to="/contacto">Contacto</Boton>
                </div>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
