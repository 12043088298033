import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import { BiError } from "react-icons/bi";
import axios from "axios";
import url from "../../../../utils/url";
import Container from "./styled";

export default function PasoUno({ pasoSiguiente }) {
  // Valores de los inputs
  const [inputValues, setInputValues] = useState({
    nombre:
      localStorage.getItem("segurocomercio-nombre") !== null
        ? localStorage.getItem("segurocomercio-nombre")
        : "",
    email:
      localStorage.getItem("segurocomercio-email") !== null
        ? localStorage.getItem("segurocomercio-email")
        : "",
    telefono:
      localStorage.getItem("segurocomercio-telefono") !== null
        ? localStorage.getItem("segurocomercio-telefono")
        : "",
  });

  // Errores del formulario
  const [formErrors, setFormErrors] = useState({});

  // Pop-up Errores
  const [popupErrores, setPopupErrores] = useState(false);

  // Toggle Pop-up Errores
  const togglePopupErrores = () => {
    setPopupErrores(!popupErrores);
  };

  // Setea el valor de los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    // Guarda el valor de cada input en el localStorage con su nombre para autocompletar el paso 3
    localStorage.setItem("segurocomercio-" + [name], value);
  };

  // Captcha
  function onChange(value) {
    setInputValues((prev) => ({ ...prev, ["captcha"]: value }));
  }

  // Validar Formulario
  const validateForm = (e) => {
    e.preventDefault();
    let errors = {};

    // Validar nombre
    if (!inputValues.nombre) {
      errors.nombre = "El nombre es requerido";
    } else if (inputValues.nombre.length < 2) {
      errors.nombre = "El nombre debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.nombre
      )
    ) {
      errors.nombre = "El nombre ingresado no es válido";
    }

    // Validar correo electrónico
    if (!inputValues.email) {
      errors.email = "El correo electrónico es requerido";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.email)) {
      errors.email = "El correo electrónico ingresado no es valido";
    }

    // Validar teléfono
    if (!inputValues.telefono) {
      errors.telefono = "El teléfono es requerido";
    } else if (inputValues.telefono.length < 8) {
      errors.telefono = "El teléfono es menor a 8 digitos";
    }

    // Validar Asunto
    if (!inputValues.asunto) {
      errors.asunto = "El asunto es requerido.";
    }

    // Validar Mensaje
    if (!inputValues.mensaje) {
      errors.mensaje = "Tu comentario es requerido.";
    }

    // Validar captcha
    if (inputValues.captcha == undefined) {
      errors.captcha = "Confirme la verificación reCaptcha";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      sendMail();
      pasoSiguiente();
    } else if (popupErrores != true) {
      togglePopupErrores();
    }
  };

  // Enviar mail
  const sendMail = async () => {
    const mailContent = `
      <html><body>
        <h1>Contaco SeguroComercio</h1>
        <p><b>Nombre:</b> ${inputValues.nombre}</p>
        <p><b>Email:</b> ${inputValues.email}</p>
        <p><b>Teléfono:</b> ${inputValues.telefono}</p>
        <p><b>Asunto:</b> ${inputValues.asunto}</p>
        <p><b>Comentario:</b> ${inputValues.mensaje}</p>
      </body></html>
      `;

    const mailData = {
      mail: ['karinal@segurobici.com.ar', 'angel@segurobici.com.ar', 'lucias@seguroweb.com.ar'],
      subject: `Contacto SeguroComercio`,
      htmlContent: mailContent,
    };

    const sendMail = await axios.post(
      `${url}/api/sendEmails?from=interno`,
      mailData
    );
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      {/* Pop up Errores */}
      {popupErrores == true && (
        <div id="popup-error">
          {formErrors.nombre != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.nombre}
            </p>
          )}
          {formErrors.email != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.email}
            </p>
          )}
          {formErrors.telefono != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.telefono}
            </p>
          )}
          {formErrors.asunto != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.asunto}
            </p>
          )}
          {formErrors.mensaje != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.mensaje}
            </p>
          )}
          {formErrors.captcha != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.captcha}
            </p>
          )}
        </div>
      )}
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container" className="opacidad05">
          <h3>¡Comunicate con nuestro equipo!</h3>
          <p id="completa">
            Completá el siguiente formulario y envianos un mensaje con tus
            consultas, pronto nos comunicaremos para ayudarte.
          </p>
          <form>
            <div id="datos-personales">
              <h4>Datos personales</h4>
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="number"
                  id="input-telefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                  className={formErrors.telefono == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-telefono"
                >
                  Teléfono
                </label>
              </div>
            </div>
            <div id="asunto">
              <h4>Asunto del mensaje</h4>
              {/* Asunto */}
              <div className="input-container" id="input-asunto-container">
                <input
                  type="text"
                  id="input-asunto"
                  name="asunto"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.asunto}
                  className={formErrors.asunto == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.asunto == null || inputValues.asunto == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-asunto"
                >
                  Asunto
                </label>
              </div>
            </div>
            <div id="mensaje-motivo">
              <h4>Contanos tus dudas</h4>
              {/* Textarea Mensaje */}
              <div className="input-container" id="textarea-mensaje-container">
                <textarea
                  type="text"
                  id="input-mensaje"
                  name="mensaje"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.mensaje ? inputValues.mensaje : ""}
                  className={formErrors.mensaje == null ? "" : "input-error"}
                ></textarea>
                <label
                  className={
                    inputValues.mensaje == null || inputValues.mensaje == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="input-mensaje"
                >
                  Ingresá tu mensaje
                </label>
              </div>
            </div>
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={onChange}
              />
            </div>
            <div id="boton-container">
              <button type="submit" onClick={validateForm}>
                Enviar<span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
          <div id="mapa-container">
            <h3>Encontranos en nuestra oficina</h3>
            {/* Mapa */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.952818422825!2d-58.38140768382813!3d-34.60535456509365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccace477850f1%3A0x4c973e8332061355!2sGesti%C3%B3n%20y%20Servicios%20S.R.L.!5e0!3m2!1ses-419!2sar!4v1639418264150!5m2!1ses-419!2sar"
              width="100%"
              height="350"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <div id="transporte-container">
              <h3>Transporte cercano:</h3>
              <div>
                <h4>Colectivos:</h4>
                <span id="linea115">115</span>
                <span id="linea130">130</span>
                <span id="linea140">140</span>
                <span id="linea180">180</span>
                <span id="linea45">45</span>
                <span id="linea50">50</span>
              </div>
              <div>
                <h4>Subtes:</h4>
                <span id="subteB">B</span>
                <span id="subteC">C</span>
                <span id="subteD">D</span>
              </div>
              <div id="trenes">
                <h4>Trenes:</h4>
                <span>Belgnrano Norte</span>
                <span>Mitre</span>
                <span>Roca</span>
                <span>Sarmiento</span>
              </div>
            </div>
          </div>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" className="opacidad05" style={style}>
                <p>Dirección:</p>
                <p id="plazos-texto">Suipacha 245 1° Piso, CABA.</p>
                <div>
                  <p>Teléfonos</p>
                  <p id="plazos-texto">
                    0810-220-2373<br></br>(011) 5263-2373
                  </p>
                </div>
                <div>
                  <p>Email</p>
                  <p id="plazos-texto">info@seguroweb.com.ar</p>
                </div>
                <div>
                  <p>Ver más</p>
                  <Boton to="/forma-parte" onClick={goToTop}>
                    Forma parte
                  </Boton>
                  <Boton to="/sobre-nosotros">Sobre nosotros</Boton>
                </div>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
