import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  justify-content: center;

  // Max Width Container
  section {
    width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Title Container & Button Container
  .title-container,
  .button-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Title
  .title-container h4 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    letter-spacing: -0.015em;
    line-height: 143.5%;
  }

  // Columnas Container
  .columnas-fade-container {
    width: 668px;
    padding: 0px 0px 0px 0px;
    margin: 56px 0px 0px 0px;
    display: flex;
    justify-content: center;

    #columnas-container {
      width: 100%;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 64px;
    }
  }

  // Columna
  .columna {
    width: 180px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  // Tag
  .tag {
    min-width: 180px;
    padding: 4px 0px 4px 0px;
    margin: 0px 0px 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-image: linear-gradient(
      45deg,
      rgba(119, 148, 229, 0.07) 0%,
      rgba(119, 148, 229, 0.07) 40%,
      rgba(150, 168, 255, 0.15) 50%,
      rgba(150, 168, 255, 0.15) 55%,
      rgba(119, 148, 229, 0.07) 65%,
      rgba(119, 148, 229, 0.07) 100%
    );
    background-size: 230%;
    animation: 3000ms ease 0s infinite normal none running shimmer;
    border: 1px solid var(--grisClaro);
    border-radius: 8px;
  }

  // Second Tag
  #second-tag {
    margin: 0px 0px 0px 0px;
  }

  // Shimmer Animación
  @keyframes shimmer {
    0% {
      background-position: -30% -30%;
    }
    100% {
      background-position: 160% 160%;
    }
  }

  // Tag Texto
  .tag p {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 192.7%;
    letter-spacing: -0.015em;
    color: var(--azul20);
    text-align: center;

    // Tag Icon
    span {
      margin: 6px 12px 0px 0px;
      font-size: 1em;
      color: var(--verde50);
    }
  }

  // Line Break
  .line-break {
    width: 100%;
  }

  // Button Container
  .button-container {
    margin: 56px 0px 0px 0px;

    // Button
    a {
      margin: 0px 0px 0px 0px;
      padding: 12px 24px 12px 24px;
      border-radius: 6px;
      color: var(--blanco);
      background-color: var(--azul60);
      cursor: pointer !important;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 13px;
      font-weight: 600;
    }

    // Button Hover
    a:hover {
      background-color: var(--azul20);
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Coberturas Container
    #coberturas-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    display: none;
  }
`;

export default Container;
