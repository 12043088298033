import React, { Component } from "react";
import Container from "./styled";
import Desplegable from "./desplegable";
import Animacion from "./animacion";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

export default class Referidos extends Component {
  // Estado que determina si esta abierta o no el desplegable
  state = {
    isActiveUno: false,
    isActiveDos: false,
  };

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de los desplegables
  handleToggleUno = () => {
    this.setState({
      isActiveUno: !this.state.isActiveUno,
      isActiveDos: false,
    });
  };

  handleToggleDos = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: !this.state.isActiveDos,
    });
  };

  render() {
    return (
      <Container>
        <div id="max-width-container">
          {/* Titulo */}
          <Fade delay={100} duration={800} triggerOnce={true}>
            <h3>
              ¡Aprovechá nuestro <span>programa de referidos</span>!
            </h3>
          </Fade>
          {/* Información Container */}
          <div id="informacion-referidos-container">
            <div id="imagen-container">
              <Fade delay={100} duration={800} triggerOnce={true}>
                <Animacion></Animacion>
              </Fade>
            </div>
            <div id="texto-container">
              <Fade delay={100} duration={800} triggerOnce={true}>
                <p id="texto-titulo">
                  ¡Invitá a tus amigos y ganá importantes premios!
                </p>
              </Fade>
              <Fade
                delay={100}
                duration={600}
                triggerOnce={true}
                className={
                  this.state.isActiveUno == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="¿Sos cliente?"
                  clickFunction={this.handleToggleUno}
                  isActive={this.state.isActiveUno}
                >
                  ¡Referí y ganá $4000!
                </Desplegable>
              </Fade>
              <Fade
                delay={100}
                duration={600}
                triggerOnce={true}
                className={
                  this.state.isActiveDos == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="¿No sos cliente?"
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  ¡Contactate a nuestro{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=541126327674&text=%C2%A1Hola%2C%20Quiero%20referir%20para%20SeguroComercio,%20no%20soy%20clinete!."
                    target="_blank"
                    id="boton-wpp"
                  >
                    WhatsApp
                  </a>
                  !
                </Desplegable>
              </Fade>
            </div>
          </div>
          <Fade delay={100} duration={800} triggerOnce={true}>
            <Link to="/referidos" id="referir-boton">
              Quiero referir
            </Link>
          </Fade>
        </div>
      </Container>
    );
  }
}
