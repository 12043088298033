import * as React from "react";
import Container from "./styled";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-scroll";
import { Fade } from "react-awesome-reveal";

export default function Cubrimos() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section>
        {/* Title Container */}
        <Fade delay={100} duration={800} className="title-container">
          {/* Title */}
          <h4>
            ¡Hay muchas coberturas para vos y tu comercio!
          </h4>
        </Fade>
        {/* Columnas Container */}
        <Fade delay={100} duration={600} className="columnas-fade-container">
          <div id="columnas-container">
            {/* Columna 1 */}
            <div className="columna">
              {/* Tag */}
              <div className="tag">
                <p>
                  <span>
                    <BsCheckLg />
                  </span>
                  Resp. Civil
                </p>
              </div>
              <div className="line-break"></div>
              {/* Tag */}
              <div className="tag" id="second-tag">
                <p>
                  <span>
                    <BsCheckLg />
                  </span>
                  Robo
                </p>
              </div>
            </div>
            {/* Columna 2 */}
            <div className="columna">
              {/* Tag */}
              <div className="tag">
                <p>
                  <span>
                    <BsCheckLg />
                  </span>
                  Incendio
                </p>
              </div>
              {/* Tag */}
              <div className="tag" id="second-tag">
                <p>
                  <span>
                    <BsCheckLg />
                  </span>
                  Urgencias
                </p>
              </div>
            </div>
            {/* Columna 3 */}
            <div className="columna">
              {/* Tag */}
              <div className="tag">
                <p>
                  <span>
                    <BsCheckLg />
                  </span>
                  Dinero en caja
                </p>
              </div>
              {/* Tag */}
              <div className="tag" id="second-tag">
                <p>
                  <span>
                    <BsCheckLg />
                  </span>
                  Linderos
                </p>
              </div>
            </div>
          </div>
        </Fade>
        {/* Button Container */}
        <Fade delay={100} duration={800} className="button-container">
          {/* Button */}
          <Link
            to="cotizador"
            smooth={true}
            spy={true}
            duration={300}
            offset={-200}
          >
            ¡Cotizá tu seguro!
          </Link>
        </Fade>
      </section>
    </Container>
  );
}
