const actividades = [
  "Fabricación de alimentos y bebidas",
  "Fabricación de productos químicos",
  "Fabricación de productos farmacéuticos",
  "Fabricación de maquinaria y equipo",
  "Fabricación de productos de plástico",
  "Fabricación de productos de caucho",
  "Fabricación de productos de papel",
  "Fabricación de productos de madera",
  "Fabricación de productos metálicos",
  "Fabricación de productos electrónicos",
  "Fabricación de textiles",
  "Fabricación de prendas de vestir",
  "Fabricación de calzado",
  "Fabricación de muebles",
  "Comercio de alimentos",
  "Comercio productos de belleza",
  "Comercio de productos electrónicos",
  "Comercio de ropa y calzado",
  "Comercio de productos para el hogar",
  "Comercio de productos deportivos",
  "Comercio de juguetes",
  "Comercio de libros y periódicos",
  "Comercio productos de salud",
  "Comercio productos de mascotas",
  "Comercio productos de jardinería",
  "Comercio productos para bebés y niños",
];

export default actividades;
