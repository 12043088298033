import * as React from "react";
import Elegi from "../elegi/index";
import Consultaste from "../consultaste/index";
import Footer from "../../footer";

export default class PasosSwitch extends React.Component {
  state = {
    paso: 1,
  };

  pasoAnterior = () => {
    const { paso } = this.state;
    this.setState({ paso: paso - 1 });
  };

  pasoSiguiente = () => {
    const { paso } = this.state;
    this.setState({ paso: paso + 1 });
  };

  render() {
    const { paso } = this.state;
    switch (paso) {
      case 1:
        return (
          <>
            <Consultaste></Consultaste>
            <Footer marcas="active"></Footer>
          </>
        );
      default:
      // No hace nada
    }
  }
}
