import * as React from "react";
import InstitucionalContainer from "../containers/institucional-container";

export default function InstitucionalPage({ pagina, titulo, tituloSpan }) {
  return (
    <InstitucionalContainer
      pagina={pagina}
      titulo={titulo}
      tituloSpan={tituloSpan}
    ></InstitucionalContainer>
  );
}
