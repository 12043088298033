import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--grisClaro);

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    margin: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Title
  h3 {
    width: 100%;
    margin: 0px 0px 80px 0px;
    text-align: center;
  }

  h3 span {
    color: var(--azul100);
  }

  // Información Referidos Container
  #informacion-referidos-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  // Información Referidos Container Div
  #texto-container,
  #imagen-container {
    width: calc(50% - 64px);
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }

  // Texto Container
  #texto-container {
    flex-wrap: wrap !important;
    justify-content: flex-start;
  }

  // Imagen Container Imagen
  #imagen-container {
    height: 321px !important;
  }

  // Imagen Container Imagen
  #imagen-container div div {
    height: 321px !important;
  }

  // Texto Titulo
  #texto-titulo {
    width: 60%;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    color: var(--negro);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    line-height: 40px;
  }

  // Desplegable Container
  .desplegable-container {
    background-color: var(--grisClaro2);
    width: 70%;
    margin: 32px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 16px;
    transition: 0.2s all;
  }

  // Desplegable Container Abierto
  .desplegable-container-abierto {
    background-color: var(--grisClaro2);
    width: 70%;
    margin: 32px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 16px;
    transition: 0.2s all;
  }

  // Referir Botón
  #referir-boton {
    margin: 64px 0px 0px 0px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    color: var(--blanco);
    background-color: var(--azul60);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    pointer-events: all !important;
  }

  // Referir Botón Hover
  #referir-boton:hover {
    background-color: var(--azul0);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }

    // Texto Titulo
    #texto-titulo {
      width: 70%;
    }

    // Desplegable Container
    .desplegable-container {
      width: 85%;
    }

    // Desplegable Container Abierto
    .desplegable-container-abierto {
      width: 85%;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }

    // Información Referidos Container
    #informacion-referidos-container {
      flex-wrap: wrap;
      justify-content: center;
    }

    // Información Referidos Container Div
    #texto-container,
    #imagen-container {
      width: 80%;
      margin: 0px 0px 0px 0px;
      justify-content: center;
    }

    #texto-container {
      margin: 40px 0px 0px 0px;
    }

    #texto-container div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
    }

    // Texto Titulo
    #texto-titulo {
      text-align: center;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 480px;
    }

    // Texto Titulo
    #texto-titulo {
      width: 100%;
    }

    // Desplegable Container
    .desplegable-container {
      width: 100%;
    }

    // Desplegable Container Abierto
    .desplegable-container-abierto {
      width: 100%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 90%;
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Title
    h3 {
      margin: 0px 0px 0px 0px;
    }

    // Imagen Container Imagen
    #imagen-container {
      display: none;
    }

    // Texto Titulo
    #texto-titulo {
      display: none;
    }

    #texto-container {
      margin: 32px 0px 0px 0px;
    }
  }
`;

export default Container;
