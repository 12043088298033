const Color = require("color-js");

const Colors = {
  // Branding Azul
  azul100: Color("#001FFF"),
  azul80: Color("#1A56FF"),
  azul60: Color("#1742E2"),
  azul40: Color("#002CCC"),
  azul20: Color("#002FB2"),
  azul0: Color("#00277F"),
  verde100: Color("#0EFEC1"),
  verde50: Color("#0DB27F"),
  sombraAzul: Color("rgba(0, 52, 253, 0.12)"),
  // Branding Naranja
  naranja100: Color("#FF9700"),
  naranja80: Color("#FFBC50"),
  naranja60: Color("#FFA733"),
  naranja40: Color("#F29500"),
  naranja20: Color("#E58800"),
  naranja0: Color("#D38200"),
  lila100: Color("#DAE4FF"),
  lila90: Color("#7794E5"),
  sombraNaranja: Color("rgba(122, 149, 238, 0.18)"),
  // Neutros
  blanco: Color("#FFFFFF"),
  negro: Color("#041726"),
  gris: Color("#98989A"),
  grisClaro: Color("#EFEFEF"),
  grisClaro2: Color("#F7F7F7"),
  // Estados de visibilidad
  verde: Color("#49C82A"),
  rojo: Color("#FF0000"),
  // Notificaciones y botones
  cielo: Color("#EDF9FF"),
};

export default Colors;
