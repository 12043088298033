import * as React from "react";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";

export default function CoberturasPlan() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section>
        {/* Tag Container */}
        <Fade delay={100} duration={800} className="tag-container">
          {/* Tag */}
          <div>
            <p>Plan Comercial</p>
          </div>
        </Fade>
        {/* Title Container */}
        <Fade delay={100} duration={800} className="title-container">
          {/* Title */}
          <h3>
            ¡Conocé nuestro plan de <span>Federación Patronal</span>!
          </h3>
        </Fade>
        {/* Logo Container */}
        <Fade delay={100} duration={800} className="logo-container">
          <img
            src="/imagenes/logos/federacionpatronal.svg"
            alt="Logotipo de Federacón Patronal Seguros"
          ></img>
        </Fade>
        {/* Cards Container */}
        <div id="cards-container">
          {/* Card Container 1 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/imagenes/home/coberturasPlan/responsabilidad.svg"
                  alt="Escudo del isologo de SeguroComercio con ícono de sirena dentro"
                ></img>
                <h4>
                  Responsabilidad<br></br>civil
                </h4>
              </div>
              <p>
                Resp. civil comprensiva de hechos que vinculan a la actividad y
                resp. civil con linderos de daños materiales a ter. personas.
              </p>
            </div>
          </Fade>
          {/* Card Container 2 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/imagenes/home/coberturasPlan/incendio.svg"
                  alt="Escudo del isologo de SeguroComercio con ícono de sirena dentro"
                ></img>
                <h4>
                  Incendio<br></br>y contenido
                </h4>
              </div>
              <p>
                Incendio y contenido cubierto por acciones directas, rayos,
                explisones, vandalismo, impactos de vehículos y mucho más.
              </p>
            </div>
          </Fade>
          {/* Card Container 3 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/imagenes/home/coberturasPlan/robo.svg"
                  alt="Escudo del isologo de SeguroComercio con ícono de sirena dentro"
                ></img>
                <h4>
                  Robo de<br></br>mobiliario
                </h4>
              </div>
              <p>
                Robo y/o hurto de contenido dentro del edificio que pertenezca
                al comercio asegurado (Con factura de mercadería).
              </p>
            </div>
          </Fade>
          {/* Card Container 4 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/imagenes/home/coberturasPlan/electronicos.svg"
                  alt="Escudo del isologo de SeguroComercio con ícono de sirena dentro"
                ></img>
                <h4>
                  Electrónicos<br></br>y cristales
                </h4>
              </div>
              <p>
                Cobertura para dispositivos electrónicos y cristales por robo,
                incendio, fallas de red eléctrica o accidentes.
              </p>
            </div>
          </Fade>
        </div>
      </section>
    </Container>
  );
}
