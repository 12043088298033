import * as React from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import PasosSwitch from "../components/pasos/switch/index";
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
`;

export default function PasosContainer() {
  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Switch de pasos*/}
      <PasosSwitch></PasosSwitch>
    </Container>
  );
}
