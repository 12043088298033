import React, { Component } from "react";
import Container from "./styled";
import Pregunta from "./pregunta";
import { Fade } from "react-awesome-reveal";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";

export default class Faqs extends Component {
  // Estado que determina si esta abierta o no la question
  state = {
    isActiveUno: false,
    isActiveDos: false,
    isActiveTres: false,
    isActiveCuatro: false,
    isActiveCinco: false,
  };

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de las questions
  handleToggleUno = () => {
    this.setState({
      isActiveUno: !this.state.isActiveUno,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
    });
  };

  handleToggleDos = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: !this.state.isActiveDos,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
    });
  };

  handleToggleTres = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: !this.state.isActiveTres,
      isActiveCuatro: false,
      isActiveCinco: false,
    });
  };

  handleToggleCuatro = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: !this.state.isActiveCuatro,
      isActiveCinco: false,
    });
  };

  handleToggleCinco = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: !this.state.isActiveCinco,
    });
  };

  render() {
    const isActiveUno = this.state.isActiveUno;
    const isActiveDos = this.state.isActiveDos;
    const isActiveTres = this.state.isActiveTres;
    const isActiveCuatro = this.state.isActiveCuatro;
    const isActiveCinco = this.state.isActiveCinco;
    return (
      <Container>
        <div id="max-width-container">
          {/* Titulo */}
          <h3>
            <span>Preguntas</span> más frecuentes
          </h3>
          {/* Preguntas Container */}
          <div id="preguntas-container">
            {/* Pregunta 1 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveUno == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Qué es un seguro integral de comercio?"
                clickFunction={this.handleToggleUno}
                isActive={this.state.isActiveUno}
              >
                Un seguro integral de comercio es un respaldo que tiene tu
                negocio y te ayuda a protegerlo a través de distintas coberturas
                como: Incendio de edificio y contenido general, robo de
                mobiliario, electrónicos, cristales, responsabilidad civil
                comprensiva, responsabilidad civil linderos, etc.
              </Pregunta>
            </Fade>
            {/* Pregunta 2 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveDos == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cubren el dinero en caja fuerte/registradora?"
                clickFunction={this.handleToggleDos}
                isActive={this.state.isActiveDos}
              >
                Si, tenemos cobertura de Robo de valores (tanto en tránsito como
                en caja).
              </Pregunta>
            </Fade>
            {/* Pregunta 3 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveTres == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cuento con asistencia?"
                clickFunction={this.handleToggleTres}
                isActive={this.state.isActiveTres}
              >
                Si, contas con asistencia las 24 hs: emergencias de cerrajería,
                electricidad, plomería y cristalería
              </Pregunta>
            </Fade>
            {/* Pregunta 4 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveCuatro == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cómo puedo pagar mi seguro?"
                clickFunction={this.handleToggleCuatro}
                isActive={this.state.isActiveCuatro}
              >
                Podés adherir el pago a tu tarjeta de crédito o débito bancario.
              </Pregunta>
            </Fade>
            {/* Pregunta 5 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveCinco == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Dónde llamar en caso de un siniestro?"
                clickFunction={this.handleToggleCinco}
                isActive={this.state.isActiveCinco}
              >
                Contactanos al 0810-220-2373, 11 2806 1540, o mandános un mail
                a:{" "}
                <a id="mailButton" href="mailto:siniestros@seguroweb.com.ar">
                  siniestros@seguroweb.com.ar
                </a>
              </Pregunta>
            </Fade>
          </div>
        </div>
      </Container>
    );
  }
}
