import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { Link } from "react-scroll";
import 'material-symbols';

export default class Plan extends React.Component {
  render() {
    return (
      <Container>
        {/* Dropdown */}
        <button
          className={
            this.props.isActive ? "dropdown-abierto" : "dropdown-cerrado"
          }
          onClick={this.props.clickFunction}
        >
          {this.props.mejorPrecio && (
            <div className="mejor-precio shimmerPrecio">
              <p>
                <span>
                  <FaMoneyBillWave></FaMoneyBillWave>
                </span>
                Mejor precio
              </p>
            </div>
          )}
          {this.props.recomendado && (
            <div className="recomendado shimmerRecomendado">
              <p>
                <span>
                  <AiFillLike></AiFillLike>
                </span>
                Recomendado
              </p>
            </div>
          )}
          {/* Logo y Plan Container */}
          <div className="logo-plan-container">
            <img alt="Logo Seguro" src={this.props.logo}></img>
            <p>{this.props.plan}</p>
          </div>
          {/* Checks Container */}
          <section className="checks-container">
            {/* Responsabilidad Civil */}
            <span>
              {this.props.resCivil ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Robo */}
            <span>
              {this.props.robo ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Seguro Técnico */}
            <span>
              {this.props.seguroTecnico ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Cristales */}
            <span>
              {this.props.cristales ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Asistencia */}
            <span>
              {this.props.incendio ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
          </section>
          {/* Botones Container */}
          <div className="botones-container">
            {/* Botón Contrata */}
            <Link
              id="contrata-boton"
              to="header"
              spy={true}
              onClick={this.props.pasoSiguiente}
            >
              Consultar
            </Link>
            {/* Botón Ver mas */}
            <button id="ver-mas-boton" onClick={this.props.clickFunction}>
              {this.props.isActive ? "Ver menos" : "Ver más"}
            </button>
          </div>
        </button>
        {/* Contenido */}
        <Collapse isOpened={this.props.isActive}>
          <div className="children-container">{this.props.children}</div>
        </Collapse>
      </Container>
    );
  }
}
