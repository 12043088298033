import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Cotizador from "../components/cotizador";
import CoberturasPlan from "../components/home/coberturasPlan";
import Cubrimos from "../components/home/cubrimos";
import Pasos from "../components/home/pasos";
import Federacion from "../components/home/federacion";
import Referidos from "../components/home/referidos";
import Beneficios from "../components/home/beneficios";
import Canales from "../components/home/canales";
import Faqs from "../components/home/faqs";
import Footer from "../components/footer";
import styled from "styled-components";
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function HomeContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  // Referidos Header
  const [isReferidosHeaderOpen, setReferidosHeaderOpen] = useState(true);
  const toggleReferidosHeader = () => {
    setReferidosHeaderOpen(!isReferidosHeaderOpen);
  };

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header
          isReferidosHeaderOpen={isReferidosHeaderOpen}
          toggleReferidosHeader={toggleReferidosHeader}
        ></Header>
      </Headroom>
      {/* Cotizador */}
      <Cotizador
        isReferidosHeaderOpen={isReferidosHeaderOpen}
        subtitle="Te cubrimos mediante Federación Patronal, número 1 en solvencia financiera"
      ></Cotizador>
      {/* Coberturas Plan*/}
      <CoberturasPlan></CoberturasPlan>
      {/* Cubrimos */}
      <Cubrimos></Cubrimos>
      {/* Pasos */}
      <Pasos></Pasos>
      {/* Referidos */}
      <Referidos></Referidos>
      {/* Carousel 
      <Carousel></Carousel> */}
      {/* Comercios Asegurados 
      <ComerciosAsegurados></ComerciosAsegurados> */}
      {/* Beneficios */}
      <Beneficios></Beneficios>
      {/* Canales de comunicacion */}
      <Canales></Canales>
      {/* FAQs */}
      <Faqs></Faqs>
      {/* Federación */}
      <Federacion></Federacion>
      {/* Footer */}
      <Footer marcas="active"></Footer>
    </Container>
  );
}
