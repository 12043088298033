import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import GlobalStyle from "./constants/globalStyles";
import HomePage from "./pages/home-page";
import PasosPage from "./pages/pasos-page";
import ContactoPage from "./pages/contacto-page";
import ReferidosPage from "./pages/referidos-page";
import BajaPage from "./pages/baja-page";
import ArrepentimientoPage from "./pages/arrepentimiento-page";
import FormaPartePage from "./pages/formaparte-page";
import InstitucionalPage from "./pages/institucional-page";

function App() {
  const urlsCanales = [
    "/",
    "/m",
    "/gs",
    "/gd",
    "/in",
    "/fb",
    "/tiktok",
    "/twitch",
  ];

  return (
    <div className="App">
      <Routes>
        {/* Home (Se redireccionan las URLs con sufijos a la Home) */}
        {urlsCanales.map((path, index) => {
          return <Route path={path} element={<HomePage />} key={index} />;
        })}
        {/* Pasos */}
        <Route exact path="/pasos" element={<PasosPage />} />
        {/* Contacto */}
        <Route exact path="/contacto" element={<ContactoPage />} />
        {/* Referidos */}
        <Route exact path="/referidos" element={<ReferidosPage />} />
        {/* Baja de servicio */}
        <Route exact path="/baja-de-servicio" element={<BajaPage />} />
        {/* Arrepentimiento de compra */}
        <Route
          exact
          path="/arrepentimiento-de-compra"
          element={<ArrepentimientoPage />}
        />
        {/* Forma Parte */}
        <Route exact path="/forma-parte" element={<FormaPartePage />} />
        {/* Privacidad */}
        <Route
          exact
          path="/politicas-y-terminos"
          element={
            <InstitucionalPage
              pagina="politicas-y-terminos"
              tituloSpan="Políticas y términos"
              titulo=" - Gestión y Servicios"
            />
          }
        />
        {/* Sobre Nosotros */}
        <Route
          exact
          path="/sobre-nosotros"
          element={
            <InstitucionalPage
              pagina="sobre-nosotros"
              tituloSpan="Sobre nosotros"
              titulo=" - Gestión y Servicios"
            />
          }
        />
      </Routes>
      <GlobalStyle></GlobalStyle>
    </div>
  );
}

export default App;
