import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 110px 0px 0px 0px;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--grisClaro);

  // Max Width Container
  .max-width-container {
    width: 100%;
    max-width: 1290px;
    margin: 64px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Columnas Container
  #columnas-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 40px 0px;
    text-align: left;
    color: var(--azul60);
  }

  // Parrafos
  p {
    width: 465px;
    margin: 0px 96px 0px 0px;
    text-align: left;
    line-height: 30px;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Logo Federacion
  #logo-federacion {
    width: 410px;
    position: absolute;
    bottom: -10px;
    right: 0px;
    opacity: 0.09;
    z-index: 98 !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    .max-width-container {
      max-width: 1000px;
    }

    // Parrafos
    p {
      width: 452px;
    }

    // Parrafo
    p:nth-child(3) {
      margin: 0px 0px 0px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    .max-width-container {
      max-width: 680px;
    }

    // Parrafos
    p {
      width: 100%;
      margin: 0px 0px 0px 0px;
    }

    // Parrafo
    p:nth-child(3) {
      margin: 32px 0px 0px 0px;
    }

    // Logo Federacion
    #logo-federacion {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    .max-width-container {
      max-width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    .max-width-container {
      max-width: 350px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Max Width Container
    .max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
