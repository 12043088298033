import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: ${(props) =>
    props.pagina == "institucional" ? "0px 0px 0px 0px" : "80px 0px 0px 0px"};
  background-color: var(--grisClaro2);
  border-top: ${(props) =>
    props.marcas != "active" ? "1px solid var(--grisClaro);" : "0px"};

  // Data Nav Container
  .data-nav-container {
    width: 100%;
    max-width: 1270px;
    padding: 88px 0px 0px 0px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  // Data Container
  .data-container {
    width: 100%;
    margin: 0px 0px 80px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  // Data Container Button
  .data-container a {
    height: 33.14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Data Container Logo Image
  .data-container a img {
    height: 100%;
    object-fit: cover;
    transition: 0.2s all;
  }

  // Data Container Logo Image Hover
  .data-container a:hover > img {
    transform: scale(0.96);
  }

  // Data
  .data {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }

  // Data Botonos
  .data a {
    margin: 0px 0px 0px 16px;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    line-height: 27px;
    font-size: 13px;
    text-align: right;
    color: var(--negro);
  }

  // Data Text
  .data p {
    margin: 0px 0px 0px 16px;
    font-size: 13px;
    text-align: right;
    line-height: normal;
  }

  // Navs Container
  .navs-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  // Navs Container Columna
  .navs-container-columna {
    width: auto;
    margin: 0px 64px 32px 0px;
  }

  // Navs Container Columna Titulo
  .navs-container-columna p {
    margin: 0px 0px 8px 0px;
    font-weight: bold;
    font-size: 13px;
    color: var(--verde50);
  }

  // Navs Container Columna Nav Li
  .navs-container-columna nav li {
    list-style-type: none;
  }

  // Navs Container Columna Nav Li Buttons
  .navs-container-columna nav li a {
    text-decoration: none;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    color: var(--negro);
    opacity: 0.8;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Navs Container Columna Nav Li Buttons Hover
  .navs-container-columna nav li a:hover {
    color: var(--azul100);
  }

  // Social Media y Logos Container
  .social-media-logos-container {
    width: 100%;
    max-width: 1270px;
    padding: 48px 0px 80px 0px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
    position: relative;
  }

  // Edificios Image
  #edificios {
    width: 310px;
    position: absolute;
    bottom: -10px;
    right: 0px;
    transform: scaleX(-1);
    opacity: 0.09;
    z-index: 98 !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  // Social Media Container
  // Social Media Mobile Container
  .social-media-container,
  .social-media-mobile-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Socil Media Mobile Container
  .social-media-mobile-container {
    display: none;
  }

  // Social Media Container Buttons
  // Social Media Mobile Container Buttons
  .social-media-container a,
  .social-media-mobile-container a {
    width: 33px;
    height: 33px;
    border-radius: 100%;
    background-color: var(--azul100);
    cursor: pointer;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--blanco);
    font-size: 15px;
    transition: 0.2s;
  }

  // Social Media Buttons Hover
  .social-media-container a:hover {
    background-color: var(--grisClaro2);
    color: var(--azul100);
    transform: scale(1.4);
  }

  // Logos Container
  .logos-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }

  // Logos Container Logos
  .logos-container img {
    display: block;
    margin-left: 35px;
    pointer-events: auto;
  }

  // Logo SW
  #logo-sw {
    max-width: 200px;
    height: 27.06px;
  }

  // Logo PDP
  #logoPDP {
    max-width: 95px;
  }

  // Logo SSN
  #logoSSN {
    max-width: 199px;
  }

  // Texto Copyright Container
  #texto-copyright-container {
    width: 100%;
    padding: 24px 0px 24px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: var(--grisClaro2);
    border-top: 1px solid #e8e8e8;
    z-index: 99 !important;
    position: relative;
  }

  // Texto Copyright Texto
  #texto-copyright-container p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    max-width: 1270px;
    margin: 0 auto;
    font-size: 13px;
    color: var(--gris);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Data Nav Container
    .data-nav-container {
      max-width: 1000px;
    }

    // Social Media y Logos Container
    .social-media-logos-container {
      max-width: 1000px;
    }

    // Texto Copyright
    #texto-copyright-container p {
      max-width: 1000px;
    }
  }

  //1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Data Nav Container
    .data-nav-container {
      max-width: 680px;
    }

    // Data Container
    .data-container {
      flex-wrap: wrap;
      margin: 0px 0px 48px 0px;
    }

    // Data
    .data {
      width: 100%;
      margin: 48px 0px 0px 0px;
      justify-content: flex-start;
    }

    // Data Botonos
    .data a {
      text-align: left;
      margin: 0px 16px 0px 0px;
    }

    // Data Text
    .data p {
      text-align: left;
      margin: 0px 16px 0px 0px;
    }

    // Navs Container
    .navs-container {
      max-width: 100%;
      justify-content: flex-start;
    }

    // Navs Container Columna
    .navs-container-columna {
      width: 206px;
      margin: 0px 0px 40px 0px;
    }

    // Orden de las columnas
    #mapa-del-sitio {
      order: 1;
    }

    #bicicleterias {
      order: 4;
    }

    #otros-seguros {
      order: 0;
    }

    #notas-del-blog {
      order: 3;
    }

    #categorias-relevantes {
      order: 7;
    }

    #la-empresa {
      width: 268px !important;
      order: 6;
    }

    #ayuda {
      order: 5;
    }

    #aseguradoras {
      width: 206px !important;
      order: 8;
    }

    // Social Media y Logos Container
    .social-media-logos-container {
      max-width: 680px;
      padding: 24px 0px 80px 0px;
    }

    // Social Media Container
    .social-media-container {
      display: none;
    }

    // Socil Media Mobile Container
    .social-media-mobile-container {
      display: flex;
    }

    // Data Fiscal Container
    .logos-container {
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      width: 80%;
      margin: -48px 0px 0px 0px;
    }

    // Logos Container Logos
    .logos-container img {
      margin: 48px 40px 0px 0px;
    }

    // Orden Logos
    #logo-sw-boton {
      order: 2;
    }

    #logo-encrypt {
      order: 4;
    }

    #logo-SSN {
      order: 3;
    }

    #logo-PDP {
      order: 1;
    }

    #logo-fiscal {
      order: 0;
    }

    // Texto Copyright
    #texto-copyright-container p {
      max-width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Data Nav Container
    .data-nav-container {
      max-width: 480px;
    }

    // Data Container
    .data-container {
      flex-wrap: wrap;
      margin: 0px 0px 32px 0px;
    }

    // Data
    .data {
      width: 70%;
      flex-wrap: wrap;
    }

    // Data Botonos
    .data a {
      text-align: left;
      margin: 0px 16px 16px 0px;
    }

    // Data Text
    .data p {
      text-align: left;
      margin: 0px 16px 16px 0px;
    }

    // Navs Container
    .navs-container {
      width: 100%;
      max-width: none;
    }

    // Navs Container Columna
    .navs-container-columna {
      width: 43% !important;
    }

    // Orden de las columnas
    #mapa-del-sitio {
      order: 3;
    }

    #bicicleterias {
      order: 4;
    }

    #otros-seguros {
      order: 0;
    }

    #notas-del-blog {
      order: 1;
    }

    #categorias-relevantes {
      order: 7;
    }

    #la-empresa {
      width: 268px !important;
      order: 6;
    }

    #ayuda {
      order: 5;
    }

    #aseguradoras {
      width: 206px !important;
      order: 8;
    }

    // Social Media y Logos Container
    .social-media-logos-container {
      max-width: 480px;
    }

    // Data Fiscal Container
    .logos-container {
      width: 100%;
    }

    // Texto Copyright
    #texto-copyright-container p {
      max-width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Data Nav Container
    .data-nav-container {
      max-width: 400px;
      justify-content: center;
    }

    // Data Container
    .data-container {
      justify-content: center;
      margin: 0px 0px 18px 0px;
    }

    // Data Container Button
    .data-container a {
      max-width: 162px;
    }

    // Data Container Logo Image
    .data-container a img {
      max-width: 162px;
    }

    // Data
    .data {
      justify-content: center;
    }

    // Data Botonos
    .data a {
      width: 100%;
      text-align: center;
      line-height: 28px;
      margin: 0px 0px 0px 0px;
    }

    // Data Text
    .data p {
      width: 100%;
      text-align: center;
      line-height: 28px;
      margin: 0px 0px 0px 0px;
    }

    // Edificios Image
    #edificios {
      right: 18%;
    }

    // Socil Media Mobile Container
    .social-media-mobile-container {
      width: 100%;
      margin: 48px 0px 0px 0px;
      justify-content: center;
    }

    // Social Media Mobile Container Buttons
    .social-media-mobile-container a {
      margin: 0px 8px 0px 8px;
    }

    // Navs Container
    .navs-container {
      width: 100%;
      justify-content: center;
    }

    // Navs Container Columna
    .navs-container-columna {
      width: 100%;
      text-align: center;
      max-width: none;
      justify-content: center;
      margin: 30px 0px 0px 0px;
    }

    #la-empresa,
    #aseguradoras,
    #otros-seguros,
    #bicicleterias {
      width: 100% !important;
    }

    // Social Media and Data Fiscal Container
    .social-media-mobile-container {
      max-width: 400px;
    }

    // Logos Container
    .logos-container {
      max-width: 400px;
      display: block;
      margin: 0 auto;
    }

    // Logos Container Logos
    .logos-container img {
      margin: 0 auto;
      padding: 32px 0px 0px 0px;
    }

    // Texto Copyright
    #texto-copyright-container p {
      width: 90%;
      max-width: 80%;
      line-height: 28px;
    }
  }
`;

export default Container;
