import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Container from "./styled";

export default function Federacion() {
  return (
    <Container>
      <Fade delay={100} duration={700} className="max-width-container">
        <div id="columnas-container">
          <h3>Federación Patronal</h3>
          <p>
            Federacion Patronal Compañía de Seguros es la aseguradora número 1
            en solvencia financiera de la República Argentina con más de 250 mil
            millones de pesos en inversiones , consolidándose también como
            número 1 en ventas de seguros en el país.<br></br>
            <br></br>A lo largo de sus más de 100 años, Federacion Patronal se
            ha caracterizado por el cumplimiento ágil y veloz en cuanto a las
            indemnizaciones por siniestros, lo cual le ha permitido sostener en
            el tiempo un liderazgo. Dicha supremacía está basada en la solidez
            financiera la cual le permite al asegurado depositar toda la
            confianza en la Compañía para cubrir su patrimonio.<br></br>
            <br></br>
            Federacion Patronal se ha propuesto desarrollar productos que
            brinden soluciones a los asegurdos. En este caso , la compañia, se
            ha especializado y ha estudiado de manera meticulosa el seguro de
            comercios con el objeto de brindarle al asegurado un seguro completo
            que tenga por objeto dar respuestas a los requerimientos escenciales
            que un emprendedor tenga para desarrollar su activifdad con la
            tranquilidad de que su fuente laboral estará garantizada.
          </p>
          <p>
            Gestión y Servicios S.R.L. Broker de Seguros , por medio de su marca
            www.seguroweb.com.ar , mantiene una alianza comercial muy importante
            desde hace más de 30 años lo que nos permite poder brindarle a
            nuestros asegurados un servicio de alta calidad : trato
            personalizado mediante nuestros asesores y todo el acompañamiento
            desde la contratación de la póliza hasta la atención especial en
            cada siniestro brindando una respuesta inmediata ante cada
            requerimiento de cada uno de nuestros asegurados.<br></br>
            <br></br>Nuestra eficiente calidad de servicio, conjuntamente con la
            solidez de Federacion Patronal, hacen que más de 100.000 asegurados
            nos brinden su confianza para asegurar sus bienes. Esto implica una
            enorme tranquilidad en el presente que avizora un futuro que
            brindará todas las garantías en el patrimonio de cada uno de
            nuestros asegurados.
          </p>
          <img
            alt="Logo de Federación Patronal"
            src="imagenes/home/federacion/federacion-borde.svg"
            id="logo-federacion"
          ></img>
        </div>
      </Fade>
    </Container>
  );
}
