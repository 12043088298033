import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 90px 0px 0px 0px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;

  // Contrataste Container
  .contrataste-container {
    width: 100%;
    max-width: 1320px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px 0px 0px 0px;
    position: relative;
  }

  // Contrataste Container
  .contrataste-container h3 {
    text-align: center;
  }

  // Animación Container
  #animacion-container {
    width: 120%;
    height: 740px;
    position: absolute;
    top: 0;
    left: -10%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    opacity: 0%;
    animation-name: animacion-completa;
    animation-duration: 1.8s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
    z-index: 100;
  }

  @keyframes animacion-completa {
    from {
      opacity: 100%;
    }
    95% {
      opacity: 100%;
    }
    to {
      opacity: 0%;
    }
  }

  // Animación Container Span
  #animacion-container span {
    width: 700px;
    height: 700px;
    position: absolute;
    border-radius: 40% 40% 100% 100%;
    background-color: #56c662;
    animation-name: circulo-verde;
    animation-duration: 2.1s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
  }

  @keyframes circulo-verde {
    from {
      width: 0px;
      height: 0px;
    }
    70% {
      width: 0px;
      height: 0px;
    }
    to {
      width: 2100px;
      height: 2100px;
    }
  }

  // Texto Contacto
  #texto-contacto {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    text-align: center;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Texto Contacto Ícono
  #texto-contacto span {
    margin: 4px 7px 0px 0px;
    font-size: 22px;
  }

  // Max Width Coberturas Container
  #max-width-coberturas-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Coberturas Container
  .coberturas-container {
    width: 100%;
    height: 115px;
    margin: 32px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    pointer-events: none;
    background-color: #fcfcfc;
    border-radius: 18px !important;
    box-shadow: 0px 5px 30px var(--sombraAzul);
    transition: 0.2s all;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 115px;
    height: 100%;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 100%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 16px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--azul60);
    line-height: 10px;
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 790px;
    margin: 0px 24px -4px 24px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 95px;
    font-size: 26px;
    text-align: center;
    color: var(--verde50);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  // Checks Container Text
  .checks-container span p {
    margin: 0px 0px 12px 0px;
    font-size: 12px;
    font-weight: 400;
    color: var(--gris);
    line-height: 14px;

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }

  // X Icono
  #check-no {
    font-size: 24px;
    color: var(--rojo) !important;
  }

  // Precio Container
  .precio-container {
    width: 135px;
    height: 100%;
    padding: 0px 0px 0px 24px;
  }

  // Datos Container
  #datos-container {
    width: 100%;
    height: 115px;
    margin: 32px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    background-color: #fcfcfc;
    border-radius: 18px !important;
    box-shadow: 0px 5px 30px var(--sombraAzul);
    transition: 0.2s all;
  }

  // Datos Comercio
  .datos-comercio,
  .datos-comercio-mobile {
    width: 115px;
    height: 100%;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
  }

  .datos-comercio img,
  .datos-comercio-mobile img {
    width: 100%;
    max-height: 27px;
    height: auto;
    object-fit: contain;
  }

  .datos-comercio p,
  .datos-comercio-mobile p {
    margin: 16px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--azul60);
    line-height: 10px;
  }

  .datos-comercio-mobile {
    display: none;
  }

  // Datos Cotización
  #datos-cotizacion {
    height: 100%;
    padding: 0px 32px 0px 32px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  // Dato
  .dato {
    width: fit-content;
    margin: 0px 32px 0px 0px;
  }

  // Dato Titulo
  .dato p {
    margin: 0px 0px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    color: var(--gris);
  }

  // Dato Texto
  .dato p:nth-child(2) {
    font-size: 14px;
    font-weight: 600;
    color: var(--verde50);
  }

  // Datos preparados
  .datos-preparados {
    width: 100%;
    margin: 80px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;

    h4 {
      width: 100%;
      margin: 0px 0px 24px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      flex-wrap: wrap;
      line-height: 28px;

      strong {
        font-size: 17px !important;
        color: var(--verde50);
        margin: 5px 12px 0px 0px !important;
      }

      span {
        color: var(--azul60) !important;
        margin: 0px 0px 0px 4px !important;
      }
    }

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      margin: 7px 0px 0px 0px;
    }

    div p {
      width: fit-content;
      margin: 0px 0px 0px 0px !important;
      color: var(--gris);
      font-weight: 400;
      font-size: 14px;
      line-height: 21px !important;
      text-align: center !important;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  // Datos Plan Federacion
  .datos-plan-federacion {
    width: 100%;
    padding: 80px 0px 0px 0px;
    margin: 80px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid var(--grisClaro);

    // Titulo
    h3 {
      width: 85%;
      margin: 0px 0px 0px 0px;
      text-align: center;
    }

    h3 span {
      color: var(--azul60);
    }
  }

  // Preguntas Container
  #preguntas-container {
    width: 100%;
    max-width: 760px;
    margin: 40px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  // Pregunta Container
  .pregunta-container {
    background-color: var(--blanco);
    width: 100%;
    margin: 42px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--grisClaro);
    border-radius: 16px;
    transition: 0.2s all;
  }

  // Pregunta Container Abierta
  .pregunta-container-abierta {
    background-color: var(--blanco);
    width: 100%;
    margin: 42px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--azul60);
    border-radius: 16px;
    transition: 0.2s all;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      width: 1000px;
    }

    // Coberturas Container
    .coberturas-container {
      justify-content: flex-start;
    }

    // Checks Container
    .checks-container {
      max-width: 788px;
      margin: 0px 0px -4px 24px;
    }

    // Checks Container Icono
    .checks-container span {
      width: 98.5px;
    }

    // Precio Container
    .precio-container {
      display: none;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    #max-width-coberturas-container {
      max-width: 480px;
      justify-content: center;
    }

    // Coberturas Container
    .coberturas-container {
      width: 279px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Datos Comercio Mobile
    .datos-comercio-mobile {
      width: 115px;
      padding: 0px 0px 0px 24px;
      display: flex;
      border: 0;
    }

    // Datos Container
    #datos-container {
      display: none;
    }

    // Preguntas Container
    #preguntas-container {
      max-width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Preguntas Container
    #preguntas-container {
      max-width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 350px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 380px;
    }

    // Datos Cotización
    #datos-cotizacion {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Preguntas Container
    #preguntas-container {
      max-width: 350px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
      justify-content: center;
    }

    .coberturas-container {
      width: 100%;
      padding: 0px 0px 0px 0px;
      height: min-content;
    }

    // Logo y Plan Container
    .logo-plan-container,
    .datos-comercio-mobile {
      width: 100%;
      height: auto;
      margin: 0px 0px 0px 0px;
      padding: 24px 0px 24px 0px;
      border-right: 0px;
    }

    .logo-plan-container {
      border-bottom: 1px solid var(--grisClaro);
    }

    // Preguntas Container
    #preguntas-container {
      max-width: 85%;
    }
  }
`;

export default Container;
